import React from "react";
import blogHER from "./images/blogHER.jpeg";

const Comedy = () => {
  return (
    <div id="comedy" style={styles.container}>
      <h1 style={styles.header}>Comedy</h1>
      <div style={styles.grid}>
        <div style={styles.item}>
          <img
            src="https://m.media-amazon.com/images/I/5191mj3wjVL.jpg"
            style={styles.image}
            className="lipstick"
            alt="I Just Want to Pee Alone"
          />
          <p style={styles.title}>I Just Want to Pee Alone</p>
          <p style={styles.description}>
            Motherhood is the toughest – and funniest – job you'll ever love.
          </p>
          <a
            href="https://www.amazon.com/I-Just-Want-Pee-Alone/dp/0988408031/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1641337735&sr=1-"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
          >
            Buy it HERE
          </a>
        </div>

        <div style={styles.item}>
          <img
            src="https://images-na.ssl-images-amazon.com/images/I/71fj6Og6oxL.jpg"
            style={styles.image}
            alt="You Have Lipstick on Your Teeth"
          />
          <p style={styles.title}>You Have Lipstick on Your Teeth</p>
          <p style={styles.description}>
            And other things you’ll only hear from your friends in the powder
            room.
          </p>
          <a
            href="https://www.amazon.com/You-Have-Lipstick-Your-Teeth/dp/1490963413"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
          >
            Buy it HERE
          </a>
        </div>

        <div style={styles.item}>
          <img
            src={blogHER}
            style={styles.image}
            alt="The Loudest Family in America"
          />
          <p style={styles.title}>The Loudest Family in America</p>
          <p style={styles.description}>BlogHER 2013 Award Winning Essay</p>
          <a
            href="https://randomhandprints.blogspot.com/2014/02/id-like-to-thank-academy.html?m=1"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
          >
            Read it HERE
          </a>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: "60px 20px",
    maxWidth: "1200px",
    margin: "0 auto",
    fontFamily: "'Open Sans', sans-serif",
    textAlign: "center",
    backgroundColor: "#ffdef9",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  },
  header: {
    fontSize: "2.5rem",
    marginBottom: "40px",
    color: "#333",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "40px",
  },
  item: {
    backgroundColor: "#ffffff",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease",
  },
  image: {
    width: "100%",
    height: "auto",
    width: "300px",
    height: "400px",
    objectFit: "cover",

    borderRadius: "10px",
    marginBottom: "15px",
  },
  title: {
    fontSize: "1.5rem",
    color: "#333",
    marginBottom: "10px",
  },
  description: {
    fontSize: "1.1rem",
    color: "#555",
    marginBottom: "15px",
  },
  link: {
    fontSize: "1rem",
    color: "#1a73e8",
    textDecoration: "underline",
    fontWeight: "bold",
    transition: "color 0.3s ease",
  },
};

export default Comedy;
