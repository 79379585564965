import React from "react";
import taplogo from "./images/taplogo.png";
import RHButton from "./images/RHButton.jpg";
import njfamily from "./images/njfamily.png";

const Editing = () => {
  return (
    <div id="editing" style={styles.container}>
      <h1 style={styles.header}>Editing</h1>

      <div style={styles.grid}>
        <div style={styles.item}>
          <img src={taplogo} alt="TAPintoSOMA.net" style={styles.image} />
          <h3 style={styles.title}>TAPintoSOMA.net</h3>
          <p style={styles.description}>
            I was the editor-in-chief of TAPintoSOMA, a hyper-local news site
            focusing on South Orange and Maplewood New Jersey from 2014 to 2018.
            I wrote and edited more than 1,000 articles on everything from local
            politics to dining and entertainment, to sports and recreation.
          </p>
          <a
            href="https://www.tapinto.net/sections/education/articles/november-snowstorm-in-soma-caused-epic-traffic-and-stranded-students-but-also-lots-of-acts-of-kindness"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
          >
            Read my work HERE
          </a>
        </div>

        <div style={styles.item}>
          <img src={RHButton} alt="RandomHandprints.com" style={styles.image} />
          <h3 style={styles.title}>RandomHandprints.com</h3>
          <p style={styles.description}>
            One of the first “mommy bloggers,” I wrote first at Big City Mom and
            later at Random Handprints for over a decade. The blog was voted one
            of the best NYC mom blogs, and one of my posts was selected as a
            “Blogher Voice of the Year” in 2013.
          </p>
          <a
            href="http://randomhandprints.blogspot.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
          >
            Visit Random Handprints HERE
          </a>
        </div>

        <div style={styles.item}>
          <img src={njfamily} alt="NJFamily.com" style={styles.image} />
          <h3 style={styles.title}>NJFamily.com</h3>
          <p style={styles.description}>
            As a Contributing Editor and one of their “Real Moms of New Jersey,”
            I covered crafts, baking, and cooking with kids, parenting hacks,
            and family activities all over New Jersey, as well as the latest
            cultural opportunities and day trips in New York City.
          </p>
          <a
            href="https://www.njfamily.com/5-tips-to-help-moms-and-dads-get-organized/"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
          >
            Read my work HERE
          </a>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: "60px 20px",
    maxWidth: "1200px",
    margin: "0 auto",
    fontFamily: "'Open Sans', sans-serif",
    textAlign: "center",
    backgroundColor: "#d6d4ff",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  },
  header: {
    fontSize: "2.5rem",
    marginBottom: "40px",
    color: "#333",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "40px",
  },
  item: {
    backgroundColor: "#ffffff",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease",
  },
  image: {
    width: "320px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "10px",
    marginBottom: "15px",
  },
  title: {
    fontSize: "1.5rem",
    color: "#333",
    marginBottom: "10px",
  },
  description: {
    fontSize: "1.1rem",
    color: "#555",
    marginBottom: "15px",
  },
  link: {
    fontSize: "1rem",
    color: "#1a73e8",
    textDecoration: "underline",
    fontWeight: "bold",
    transition: "color 0.3s ease",
  },
};

export default Editing;
