import React from "react";
import huffpost from "./images/huffpost.png";
import barista from "./images/baristanet.png";
import westfield from "./images/westfield.png";

const Articles = () => {
  return (
    <div id="articles" style={styles.container}>
      <h1 style={styles.header}>Articles</h1>

      <div style={styles.grid}>
        <div style={styles.item}>
          <img src={huffpost} alt="The Huffington Post" style={styles.image} />
          <h3 style={styles.title}>The Huffington Post</h3>
          <p style={styles.description}>
            I was a contributing writer at The Huffington Post, where I shared
            hacks for lots of things, but went viral for sharing how to wrap
            gifts without any tape. Or scissors.
          </p>
          <a
            href="https://www.huffpost.com/author/anna-sandler"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
          >
            Read my work HERE
          </a>
        </div>

        <div style={styles.item}>
          <img src={barista} alt="Baristanet" style={styles.image} />
          <h3 style={styles.title}>Baristanet</h3>
          <p style={styles.description}>
            This hyperlocal news site focuses primarily on Montclair, but with
            some local coverage of Maplewood as well, which was my usual beat. I
            also wrote reviews of new restaurants, books, and performances at
            SOPAC.
          </p>
          <a
            href="https://baristanet.com/writer/anna-sandler/"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
          >
            Read my work HERE
          </a>
        </div>

        <div style={styles.item}>
          <img
            src={westfield}
            alt="Wonders of Westfield"
            style={styles.image}
          />
          <h3 style={styles.title}>Wonders of Westfield</h3>
          <p style={styles.description}>
            Covering all things Westfield, I found the best places to eat,
            drink, people watch, or take your rambunctious toddler.
          </p>
          <a
            href="https://www.facebook.com/WondersOfWestfield/"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
          >
            Visit their Facebook page HERE
          </a>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: "60px 20px",
    maxWidth: "1200px",
    margin: "0 auto",
    fontFamily: "'Open Sans', sans-serif",
    textAlign: "center",
    backgroundColor: "#d4eeff", // Matching the background color with Editing component
    borderRadius: "10px", // Adding border radius to match the style
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Adding box shadow for consistency
  },
  header: {
    fontSize: "2.5rem",
    marginBottom: "40px",
    color: "#333",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "40px",
  },
  item: {
    backgroundColor: "#ffffff",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease",
    textAlign: "left",
    height: "450px", // Ensure consistent height for all items
  },
  image: {
    width: "300px",
    height: "90px", // Fixed height to maintain consistency
    objectFit: "cover", // Ensure the image covers the entire area
    borderRadius: "10px",
    marginBottom: "15px",
  },
  title: {
    fontSize: "1.5rem",
    color: "#333",
    marginBottom: "10px",
  },
  description: {
    fontSize: "1.1rem",
    color: "#555",
    marginBottom: "15px",
  },
  link: {
    fontSize: "1rem",
    color: "#1a73e8",
    textDecoration: "none",
    fontWeight: "bold",
    transition: "color 0.3s ease",
  },
  itemHover: {
    transform: "scale(1.05)",
  },
  aHover: {
    textDecoration: "underline",
  },
};

export default Articles;
