import React from "react";

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <p>&copy; 2024 Molly Sandler. All rights reserved.</p>
      <p>
        <a
          href="https://github.com/mollysandler"
          target="_blank"
          rel="noopener noreferrer"
        >
          GitHub
        </a>{" "}
        |{" "}
        <a
          href="https://linkedin.com/mollysandler"
          target="_blank"
          rel="noopener noreferrer"
        >
          Linkedin
        </a>{" "}
        |<a href="mailto:molly.sandler13@gmail.com">Email</a>
      </p>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: "#333",
    color: "#fff",
    padding: "20px",
    textAlign: "center",
    marginTop: "40px",
  },
};

export default Footer;
