import React, { useState } from "react";
import "./styles/Header.css";
import { Link } from "react-scroll";
import fancy from "./images/fancy.png";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <div className="logo">
        <Link
          to="home"
          className="nav-links"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onClick={() => setIsOpen(false)}
        >
          <img src={fancy} alt="Anna Sandler" />
        </Link>
      </div>
      <nav className={`nav-container ${isOpen ? "active" : ""}`}>
        <ul className="nav-item">
          <li>
            <Link
              to="home"
              className="nav-links"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => setIsOpen(false)}
            >
              HOME
            </Link>
          </li>
          <li>
            <Link
              to="about"
              className="nav-links"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => setIsOpen(false)}
            >
              ABOUT
            </Link>
          </li>
          <li>
            <Link
              to="comedy"
              className="nav-links"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => setIsOpen(false)}
            >
              COMEDY
            </Link>
          </li>
          <li>
            <Link
              to="editing"
              className="nav-links"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => setIsOpen(false)}
            >
              EDITING
            </Link>
          </li>
          <li>
            <Link
              to="articles"
              className="nav-links"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => setIsOpen(false)}
            >
              ARTICLES
            </Link>
          </li>
        </ul>
      </nav>
      <div className="hamburger" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </header>
  );
};

export default Header;
