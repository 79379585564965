import React from "react";
import {
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiOutlineLinkedin,
  AiOutlineFacebook,
} from "react-icons/ai";
import mom from "./images/mom.png";
import selfie from "./images/selfie.jpeg";

const About = () => {
  return (
    <div id="about" style={styles.aboutContainer}>
      <h1 style={styles.header}>About Me</h1>
      <div style={styles.contentContainer}>
        <p style={styles.aboutText}>
          Anna Sandler is a longtime resident of scenic New Jersey, but a New
          Yorker at heart. A graduate of Wesleyan University and the Simmons
          Graduate School of Management, Anna has been writing online since 1996
          for a variety of publications including The Huffington Post and New
          Jersey Family magazine, where she was a contributing editor. In
          addition to covering the local news as a reporter and editor for
          <a
            href="https://www.tapinto.net/towns/soma/"
            target="_blank"
            style={styles.link}
          >
            TAPintoSOMA.com
          </a>
          , she was a partner at Sandler and Wald Social Media, a consulting
          firm focused on helping small businesses navigate the online space.
          Anna chronicled modern motherhood and mayhem on her humor blog,
          <a
            href="http://randomhandprints.blogspot.com/"
            target="_blank"
            style={styles.link}
          >
            RandomHandprints.com
          </a>
          , and received national coverage from publications such as the New
          York Post, ABC News, and the Associated Press for her efforts to ease
          the gas crisis during Hurricane Sandy via the Twitter account @NJGas.
        </p>

        <div style={styles.photoAndIconsContainer}>
          <div style={styles.photoContainer}>
            <img src={mom} alt="Anna's mother" style={styles.image} />
            <img src={selfie} alt="Selfie" style={styles.image} />
          </div>
          <div id="links" style={styles.socialContainer}>
            <a href="https://www.instagram.com/whyisanna/" target="_blank">
              <AiOutlineInstagram style={styles.socialIcon} />
            </a>
            <a href="https://twitter.com/anna_sandler" target="_blank">
              <AiOutlineTwitter style={styles.socialIcon} />
            </a>
            <a
              href="https://www.facebook.com/RandomHandprints/"
              target="_blank"
            >
              <AiOutlineFacebook style={styles.socialIcon} />
            </a>
            <a
              href="https://www.linkedin.com/in/anna-sandler-92b614/"
              target="_blank"
            >
              <AiOutlineLinkedin style={styles.socialIcon} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  aboutContainer: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#EAE7DC", // light tan background
    padding: "40px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "20px",
    alignItems: "center",
  },
  header: {
    fontSize: "3rem",
    fontFamily: "'Playfair Display', serif",
    marginBottom: "20px",
    color: "#3e3e3e",
    textAlign: "center",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    maxWidth: "900px",
    width: "100%",
    marginBottom: "30px",
  },
  aboutText: {
    fontSize: "1.2rem",
    lineHeight: "1.6",
    fontFamily: "'Open Sans', sans-serif",
    color: "#333",
    maxWidth: "700px",
    marginBottom: "30px",
  },
  link: {
    color: "#1a73e8",
    textDecoration: "underline",
  },
  photoAndIconsContainer: {
    display: "flex",
    flexDirection: "row", // Align the icons and photos in a row
    justifyContent: "center",
    alignItems: "center",
    gap: "40px", // Gap between the icons and the photos
    marginBottom: "30px",
  },
  socialContainer: {
    display: "flex",
    flexDirection: "column", // Stack the icons vertically
    alignItems: "center",
    gap: "20px",
  },
  socialIcon: {
    fontSize: "50px", // Adjust the size of the icons
    transition: "transform 0.3s ease",
    cursor: "pointer",
    color: "black",
  },
  photoContainer: {
    display: "flex",
    gap: "40px", // Gap between the photos
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "250px",
    height: "300px",
    borderRadius: "10px",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
  },
};

export default About;
