import React from "react";
import anna from "./images/anna.png";
import fred from "./images/fred.png";
import cute from "./images/cute_mom.jpeg";
import Typewriter from "typewriter-effect";
const Home = () => {
  return (
    <div id="home" style={styles.homeContainer}>
      <div style={styles.contentContainer}>
        <div style={styles.textContainer}>
          <h1 style={styles.header}>Anna Sandler</h1>
          <h2 style={styles.subHeader}>
            <Typewriter
              options={{
                strings: [
                  "author",
                  "freelance writer",
                  "editor",
                  "social media manager",
                ],
                autoStart: true,
                loop: true,
              }}
            />
          </h2>
          <p style={styles.intro}>
            Welcome to my website! I am Anna Sandler, a passionate writer with
            over three decades of experience in creating compelling stories,
            insightful articles, and polished editorial content. Explore my
            work, and let's connect if you're looking for an experienced writer
            who can bring your ideas to life.
          </p>
        </div>
        <div style={styles.photoContainer}>
          <img src={anna} alt="Anna Sandler" style={styles.image} />
          <img src={fred} alt="TAPintoSOMA" style={styles.image} />
          <img src={cute} alt="Anna in the Wild" style={styles.image} />
        </div>
      </div>
    </div>
  );
};

const styles = {
  homeContainer: {
    position: "relative",
    width: "100%",
    height: "800px",
    backgroundColor: "#cdf3b0", // sage green
    display: "flex",
    borderRadius: "20px",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  contentContainer: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "1200px",
    width: "100%",
    padding: "0 20px",
  },
  textContainer: {
    flex: 1,
    textAlign: "center",
    color: "#333",
  },
  header: {
    fontSize: "3.5rem",
    fontFamily: "'Playfair Display', serif",
    marginBottom: "15px",
    color: "#3e3e3e",
    textAlign: "center",
  },
  subHeader: {
    fontSize: "1.7rem",
    color: "#555",
    fontFamily: "'Open Sans', sans-serif",
    marginBottom: "20px",
    textAlign: "center",
  },
  intro: {
    maxWidth: "600px",
    fontSize: "1.2rem",
    textAlign: "center",
    lineHeight: "1.8",
    color: "#333",
    fontFamily: "'Open Sans', sans-serif",
  },
  photoContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column", // Stack the images vertically
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    marginTop: "120px",
    marginBottom: "120px",
    height: "650px", // Set a fixed height for the container
    // overflowY: "scroll", // Enable vertical scrolling
  },
  image: {
    width: "350px", // Set consistent width
    height: "350px", // Set consistent height
    borderRadius: "15px",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
    transform: "scale(1)",
    transition: "transform 0.4s ease-in-out",
  },
  imageHover: {
    transform: "scale(1.05)",
  },
};

export default Home;
