import React from "react";
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Comedy from "./components/Comedy";
import Editing from "./components/Editing";
import Articles from "./components/Articles";
import Footer from "./components/Footer"; // Add a footer

function App() {
  return (
    <div>
      {/* Fixed Header for navigation */}
      <Header />

      {/* Main content sections */}
      <main style={styles.mainContainer}>
        <section id="home">
          <Home />
        </section>

        <section id="about">
          <About />
        </section>

        <section id="comedy">
          <Comedy />
        </section>

        <section id="editing">
          <Editing />
        </section>

        <section id="articles">
          <Articles />
        </section>
      </main>

      {/* Footer with social links, copyright info */}
      <Footer />
    </div>
  );
}

const styles = {
  mainContainer: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "0 20px",
    fontFamily: "'Open Sans', sans-serif",
  },
};

export default App;
